import React from "react";

import { ReactComponent as EyeOpen } from "../Svgs/Eye.svg";
import { ReactComponent as EyeClose } from "../Svgs/EyeClose.svg";
import { ReactComponent as CaretRight } from "../Svgs/CaretRight.svg";
import { ReactComponent as CaretDown } from "../Svgs/CaretDown.svg";
import { ReactComponent as RadioBulb } from "../Svgs/RadioBulb.svg";
import { ReactComponent as DropDown } from "../Svgs/DropDown.svg";
import { ReactComponent as SaveFloppy } from "../Svgs/SaveFloppy.svg";
import { ReactComponent as EditSvg } from "../Svgs/edit.svg";
import { ReactComponent as DeleteSvg } from "../Svgs/delete.svg";
import { ReactComponent as CrossSvg } from "../Svgs/cross.svg";
import { ReactComponent as DownloadSvg } from "../Svgs/download.svg";
import { ReactComponent as PlaySvg } from "../Svgs/play.svg";
import { ReactComponent as RefreshSvg } from "../Svgs/refresh.svg";

export const SvgComponent = ({ svgName, className, onClick }) => {
    const svgMap = {
      refresh:RefreshSvg,
      play:PlaySvg,
      download:DownloadSvg,
      cross:CrossSvg,
      delete:DeleteSvg,
      edit:EditSvg,
      EyeOpen: EyeOpen,
      EyeClose: EyeClose,
      CaretRight : CaretRight,
      CaretDown : CaretDown,
      RadioBulb : RadioBulb,
      DropDown : DropDown,
      SaveFloppy : SaveFloppy
    };
    const SelectedSvg = svgMap[svgName];
    return <SelectedSvg onClick={onClick} className={className} />;
  };
  