import React,{useState,useEffect} from 'react'
import { SvgComponent } from '../../SvgComponent'
import { TextInput,ButtonComp } from '../../Utils'
import Alert from '../../Alerts/Alert'
import { toast } from 'react-toastify'
import jobs from '../../../api/services/jobs'

function DeleteIndice({handleClose=()=>{},handleDelete=()=>{},loading=false}) {

  return (
    <div className='w-[30vw] h-auto overflow-y-auto max-h-[60vh] space-y-2 p-2'>
        <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
            <div className='text-24 text-black/50'></div>
            <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
                <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
            </div>
        </div>
        <div className='w-full space-y-4 py-4 px-2 h-full'>
             <Alert message="Are you sure you want to delete?" textColor='text-[red]/50' bgColor='bg-[red]/10' />    
          </div>


        <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
          <ButtonComp text={loading ? "Deleting" : "Delete"} btnColor="bg-red hover:bg-red/80"  BtnClass={`h-10  ${loading?'w-3/12 pointer-events-none':'w-2/12'}`} TextClass='text-16 tracking-wider' onClick={handleDelete}/>

          <ButtonComp text={"Cancel"} BtnClass={`h-10 w-2/12`} btnColor="bg-[gray] hover:bg-[gray]/70" TextClass='text-16 tracking-wider' onClick={handleClose}/>
          
        </div>
    </div>
  )
}

export default DeleteIndice