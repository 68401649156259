// import axios from 'axios'
// import {API_LIST} from "../config";

// const coreApiForSymbol = API_LIST.systemApi

// axios.defaults.baseURL = coreApiForSymbol

// axios.interceptors.response.use(null, error => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500

//   if(axios.isCancel(error)){
//     return error.message
//   }

//   const message = error.response && error.response.data && error.response.data.message
//   if(message){
//     error.message = message
//   }
//   if (!expectedError) {
//     // logger.log(error)
//     // alerts.showError(error)
//     //logout() //temp comment
//   }

//   return Promise.reject(error)
// })

// export function setJwt() {
//   const jwt = localStorage.getItem('state')

//   if (jwt)
// {
//   console.log("JWTTTTT")
//   axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
// }
// else {
//   console.log("not Work")
// }
// }
// export default {
//   axios,
//   setJwt,
//   get: axios.get,
//   post: axios.post,
//   put: axios.put,
//   patch: axios.patch,
//   delete: axios.delete,
//   CancelToken: axios.CancelToken,

// }

// Milad work for Token

// const coreApiBaseUrl = API_LIST.systemApi;

// axios.create({
//   baseURL: coreApiBaseUrl,
// });
import axios from "axios";
import { API_LIST } from "../config";

const coreApiForSymbol = API_LIST.systemApi;

axios.defaults.baseURL = coreApiForSymbol;



axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default {
  axios,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  CancelToken: axios.CancelToken,
};
