import React,{useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {timeSetter} from '../../Store/Slice/table'


function TimeFormat() {
  const dispatch = useDispatch()
  const timeZone = useSelector((state) => state.table.formatTime)
  const zoneData = [{id:"sydney",value:"Sydney",title:"Sydney"},{id:"perth",value:"Perth",title:"Perth"},{id:"utc",value:"UTC",title:"UTC"},{id:"usa",value:"USA",title:"USA"},]

  const handleTimeZoneChange = (timeZone) => {
    dispatch(timeSetter(timeZone))
  };


  return (
    <div className="flex space-x-4">
    {zoneData?.map((item,i)=>(
      <div key={i} className='flex items-center'>
      <input
        type="checkbox"
        value={item?.value}
        checked={timeZone === item?.value}
        onChange={() => handleTimeZoneChange(item?.value)}
        className="form-checkbox cursor-pointer h-4 w-4"
        id={item?.id}
      />
      <label htmlFor={item?.id} className="ml-2 cursor-pointer text-16 text-primaryColor">{item?.title}</label>
    </div>
    ))}
   
  </div>
  )
}

export default TimeFormat