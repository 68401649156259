import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../Components/Images/logo.png";
import { TextInput, ButtonComp, EventHandler } from "../../Components/Utils";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { realSubTab } from "../../Store/Slice/SubTab";
import { tokenSetter } from "../../Store/Slice/Token";
import axios from "axios";
import { API_LIST } from "../../api/config";

export default function Login() {
  const [toggleEye, setToggleEye] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(null);
  const SubSliceTab = useSelector((state) => state.SelectedSubTab.initialState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Get the navigate function
  const navigate = useNavigate();

  // Function to handle login and navigate to a different route
  // const handleLogin = () => {
  //   if (email == "admin@marketalertpro.com" && password == "admin123") {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //       dispatch(tokenSetter(true));
  //       localStorage.setItem("token", true);
  //       // navigate(`/symbols-pricing/${SubSliceTab}`);
  //       navigate(`/market-alert`);
  //     }, 1000);
  //   } else {
  //     setShowError("You are not an admin");
  //     setTimeout(() => {
  //       setShowError(null);
  //     }, 3000);
  //   }
  // };

  const handleLogin = () => {
    if (email && password) {
      // Check if email and password are not empty
      setLoading(true); // Set loading state to true
      axios
        .post(`${API_LIST.systemApi}admin/login`, { email, password })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            console.log(response);
            dispatch(tokenSetter(true));
            console.log(response.data);
            localStorage.setItem("token", response.data.token);
            navigate(`/market-alert`);
          } else {
            setShowError(response.data.message);
            setTimeout(() => setShowError(null), 3000);
          }
        })
        .catch((error) => {
          setLoading(false); //
          setShowError("An error occurred. Please try again."); //
          setTimeout(() => setShowError(null), 3000); //
        });
    } else {
      setShowError("Please enter both email and password."); //
      setTimeout(() => setShowError(null), 3000);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="flex flex-col h-[100vh] w-full space-y-4 pt-36 items-center">
      <div className="mb-10">
        <img src={Logo} width={"450"} alt="Logo" />
      </div>
      {showError && <div className="text-red">{showError}</div>}
      <div className="space-y-6 w-[20vw] flex-col">
        <TextInput
          label="Email"
          value={email}
          classNames="w-full h-12 rounded-md"
          onChange={EventHandler(setEmail)}
        />
        <TextInput
          label="Password"
          handleKeyPress={handleKeyPress}
          types={toggleEye ? "password" : "text"}
          value={password}
          onChange={EventHandler(setPassword)}
          classNames={`h-10 w-full fill-none cursor-pointer h-12 rounded-md ${
            toggleEye ? "stroke-border" : "stroke-black"
          }`}
          Svg_Name={toggleEye ? "EyeClose" : "EyeOpen"}
          onClick={() => setToggleEye(!toggleEye)}
        />

        {/* Use the handleLogin function when the button is clicked */}
        <div className="w-full h-full flex justify-center">
          <ButtonComp
            text={loading ? "SIGNING IN..." : "SIGN IN"}
            BtnClass={`h-12 w-6/12`}
            TextClass="text-16 tracking-wider"
            onClick={handleLogin}
          />
        </div>
      </div>
    </div>
  );
}
