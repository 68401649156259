import React,{useState,useEffect} from 'react'
import { SvgComponent } from '../../SvgComponent'
import { TextInput,ButtonComp } from '../../Utils'
import Alert from '../../Alerts/Alert'
import { toast } from 'react-toastify'
import jobs from '../../../api/services/jobs'

function Refresh({handleClose=()=>{},handleSave=()=>{},email,currentTab}) {
    const [code,setCode] = useState('')
    const [codeVal,setCodeVal] = useState(null);
    const [loading,setLoading] = useState(false)
    // const [refreshEmail , setRefreshEmail] = useState("abdul.rehman@dimensionalsys.com");


  useEffect(() => {
    console.log("currentTab",currentTab)
    codeGenerator()
  }, [])
  

    const codeGenerator = ()=>{
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
       }

     setCode(code)
    }

    const onSave = async()=>{
        if(code == codeVal){
          setLoading(true)
          const res = await jobs.sendEmail({email:email,refreshType:currentTab?.message == 'Stock Lists' ? 'Plus Lists' : currentTab?.message })
          if(res?.data?.success){
            handleSave()
          }else{
            toast.error("some error occured")
          }
          setLoading(false)
        }
    }

    const handleKeyPress = (event)=>{
      if (event.key === 'Enter') {
        onSave()
      }
    }

  return (
    <div className='w-[30vw] h-auto overflow-y-auto max-h-[60vh] space-y-2 p-2'>
        <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
            <div className='text-24 text-black/50'></div>
            <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
                <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
            </div>
        </div>
        <div className='w-full space-y-4 py-4 px-2 h-full'>
    <Alert message='Are you sure you want to refresh?' textColor='text-[red]/50' bgColor='bg-[red]/10' />
     <div className='w-9/12'>
          {/* <div className='text-16 font-bold text-black/60'>Total Files</div> */}
          <input
            placeholder="Email"
            className="h-12 w-full cursor-not-allowed text-black/80 tracking-wider px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="text"
            value={email}
            onCopy={(e) => e.preventDefault()}
            autoComplete={false}
            disabled
            />
          </div>
        <div className='w-9/12'>
          {/* <div className='text-16 font-bold text-black/60'>Total Files</div> */}
          <input
            placeholder=""
            className="h-12 w-full cursor-not-allowed text-black/80 tracking-wider px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="text"
            value={code}
            onCopy={(e) => e.preventDefault()}
            autoComplete={false}
            disabled
            />
          </div>
       

          <div className='w-9/12 flex items-center space-x-2'>
          {/* <div className='text-16 font-bold text-black/60'>Total Files</div> */}
          <input
            placeholder="Type above code here"
            className="h-12 w-full px-2 mt-2 place-items-center text-black/80 text-16 hover:bg-white/60  bg-white/20 border border-gray rounded-md placeholder-border outline-none"
            type="text"
            onChange={(e)=>setCodeVal(e.target.value)}
            value={codeVal}
            onPaste={(e) => e.preventDefault()}
            autoComplete={false}
            onKeyPress={handleKeyPress}
            />
          <div>
          {  
          code == codeVal?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1F7817" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            : codeVal ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E6312A" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg> : ''
          }  
          </div>
          </div>
          </div>


        <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
          <ButtonComp text={loading ? "Submitting" :"Submit"} BtnClass={`h-10 ${loading?'w-3/12':'w-2/12'}  ${code == codeVal ? '':'pointer-events-none opacity-60'}`} TextClass='text-16 tracking-wider' onClick={onSave}/>

          <ButtonComp text={"Cancel"} btnColor="bg-[gray] hover:bg-[gray]/70" BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleClose}/>
          
        </div>
    </div>
  )
}

export default Refresh