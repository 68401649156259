import http from "../interceptor/https";
import { API_LIST } from "../config";
import axios from "axios";

// const token = localStorage.getItem("token");
// const config = {
//   headers: {
//     authorization: token,
//   },
// };


export default {
  getJobData: async () => {
    try {
      const res = await http.get(
        "/admin/get-configuration"
        // config
      );
      if (res?.status === 200) {
        return res?.data?.payload;
      } else {
        return false;
      }
    } catch (error) {
      return false
    }
  },
  getDataJobLogs: async (payload) => {
    try {
      const res = await axios.post(
        `${API_LIST.coreApi}/admin/getDataJobLogs`,
        payload
      );
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getCustomJobLogs: async (payload) => {
    try {
      const res = await axios.post(
        `${API_LIST.coreApi}/admin/getCustomJobs`,
        payload
      );
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  updateCustomJob: async (payload) => {
    try {
      const res = await axios.post(
        `${API_LIST.coreApi}/admin/updateCustomJobs`,
        payload
      );
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getJobSymbolList: async (payload) => {
    try {
      const res = await axios.get(
        `${API_LIST.coreApi}/symbols/norgate-data/${payload}`
      );
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  updateStatus: async (payload) => {
    try {
      const res = await http.post("/admin/status-change", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  updateConfig: async (payload) => {
    try {
      const res = await http.post("/admin/update-config", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  addIndice: async (payload) => {
    try {
      const res = await http.post("/admin/add-indice", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  updateIndice: async (payload) => {
    try {
      const res = await http.post("/admin/update-indices", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  getIndice: async (country) => {
    try {
      const res = await http.get(`/admin/get-indice-admin/${country}`);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  deleteIndice: async (id) => {
    try {
      const res = await http.get(`/admin/delete-indices/${id}`);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  insertTemplate: async (payload) => {
    try {
      const res = await http.post("/admin/insert-template", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  sendEmail: async (payload) => {
    try {
      const res = await http.post("/admin/refresh-data-code", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  sendVerifyCode: async (payload) => {
    try {
      const res = await http.post("/admin/verify-refresh-data-code", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  globalEmail: async (payload) => {
    try {
      const res = await http.post("/admin/update-email-globally", payload);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  getAllTemplate: async () => {
    try {
      const res = await http.get("/admin/get-template");
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  downloadJobs: async (url) => {
    try {
      const res = await http.get(`/admin/${url}`);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  ftpJobReporting: async (limit) => {
    try {
      const res = await http.get(`/admin/get-ftp-job-logs/${limit}`);
      if (res?.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
};
