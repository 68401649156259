import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../Images/logo.png";
import { SvgComponent } from "../SvgComponent";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import ASX from "../../Pages/Symbols/Pricing/ASX";
import Login from "../../Pages/Login";
import { realTab } from "../../Store/Slice/MainTab";
import { realSubTab } from "../../Store/Slice/SubTab";
import {DWMTab} from "../../Store/Slice/DWM"
import MarketNasdaq from "../../Pages/Symbols/Pricing/Nasdaq";
import MarketNyse from "../../Pages/Symbols/Pricing";
import ContentPage from "../../Pages/ContentPage";
import {list} from '../../Pages/ContentPage/utils'
import DataJobs from "../../Pages/DataJobs";
import AllJobs from "../../Pages/AllJobs";
import JobReporting from "../../Pages/JobReporting";
import EmailTemplate from "../../Pages/EmailTemplate";
import NotFound from "../../Pages/NotFound";
import Alert from "../Alerts/Alert";
import AddIndices from "../../Pages/AddIndices";
import GlobalSettings from "../../Pages/GlobalSettings";


export default function RightNavigatoinBar() {
  const navigate = useNavigate()
  const mainSliceTab = useSelector((state) => state.selectedTab.initialState);
  const dispatch = useDispatch();
  const location  = useLocation()
  const SubSliceTab = useSelector((state) => state.SelectedSubTab.initialState);
  const token = localStorage.getItem('token')
  const UserToken = useSelector((state) => state.auth.token);
   const schedulerSlice=useSelector( (state)=>state.SelectedDWMTab.initialState)
  const jobDownloading = useSelector((state) => state.table.downloadLoading);
  const jobDownloadingMessage = useSelector((state) => state.table.downloadMessage);
  const [subMenu, setSubMenu] = useState(false);
  const [openschedulerSlice, setOpenschedulerSlice] = useState(false);

  
  useEffect(() => {
    if(UserToken || token){
     return
    }else{
     navigate('/login')
    }
   }, [])
   

  const TabName = [
    // {
    //   id: "01",
    //   name: "Symbols / pricing",
    //   routihg: `symbols-pricing/${SubSliceTab}`,
    // },
    { id: "2", name: "Market Alert",key:"market-alert", routihg: "/market-alert" },
    { id: "3", name: "Market Heat",key:"market-heat", routihg: "/market-heat" },
    { id: "4", name: "Heat Fives",key:"heat-fives", routihg: "/heat-fives" },
    { id: "5", name: "Watchlist",key:"watchlist", routihg: "/watchlist" },
    { id: "6", name: "Plus Stock Lists",key:"stock-lists", routihg: "/stock-lists" },
    { id: "7", name: "Premium Stock Lists",key:"premium-lists", routihg: "/premium-lists" },
    { id: "8", name: "Stock Alert",key:"stock-alert", routihg: "/stock-alert" },
    // { id: "8", name: "Company Reports",key:"report", routihg: "/report" },

    // { id: "9", name: "Data Jobs",key:"data-job", routihg: "/data-job" },
    // { id: "10", name: "All Jobs",key:"all-job", routihg: "/all-job" },
    { id: "11", name: "Job Reporting",key:"job-reporting", routihg: "/job-reporting" },
    { id: "12", name: "Add Indices",key:"add-indices", routihg: "/add-indices" },
    { id: "13", name: "Global Settings",key:"global-settings", routihg: "/global-settings" },
    // { id: "12", name: "EMAIL TEMPLATE",key:"email-template", routihg: "/email-template" },
    
  ];

    
  const subTab = [
    {
      asx: [
        { Routine: "Daily" },
        { Routine: "Weekly" },
        { Routine: "Monthly" },
      ],
      nasdaq: [
        {
          day: "daily",
          week: "weekly",
          month: "monthly",
        },
      ],
      nyse: [
        {
          day: "daily",
          week: "weekly",
          month: "monthly",
        },
      ],
    },
  ];

    useEffect(()=>{
      const path = location?.pathname?.substring(1);
      const selectedData = TabName?.find((item)=>item?.key == path)
      dispatch(realTab(selectedData?.name));
    },[])

    useEffect(() => {
      const path = location?.pathname?.substring(1);
      const selectedData = TabName?.find((item)=>item?.key == path)
      dispatch(realTab(selectedData?.name));
    }, [location?.pathname]);
  
    const HandleSubMenu = (e) => {
    if (e === "Symbols / pricing") {
      setSubMenu(!subMenu);
        dispatch(realTab(e));
      setOpenschedulerSlice(false);
    } else {
      dispatch(realTab(e));
      setSubMenu(false);
    }
  };

    useEffect(() => {
    console.log("mainSliceTab in RoutesNavigation:", mainSliceTab);

  }, [mainSliceTab, SubSliceTab,schedulerSlice]);

  
  return (
    
      <div className="flex h-[100vh] w-full overflow-auto bg-white py-4 px-5">
        <div className="flex w-1/5 h-full flex-col bg-optionsBG rounded-md border border-border/50 space-y-5">
          <div className="w-full flex items-center space-y-1 flex-col pt-4 justify-center">
            <div>
              <img src={Logo} className="w-56" />
            </div>
            <div className="bg-lightgreen/10 rounded-md mb-2 px-2 text-14 text-primaryColor/80 font-semibold">Hi, Admin</div>
          </div>
          <div className="flex flex-col">
          
            {TabName?.map((e) => (
              <ul>
                <li>
                  <Link to={`${e.routihg}`}>
                    <div
                      className={`flex h-14 hover:bg-black/10 hover:text-black/60 items-center justify-between border-b border-border px-5 font-semibold text-16 cursor-pointer ${
                        e.name === mainSliceTab
                          ? "text-white bg-primaryColor"
                          : "text-black/60 bg-headerBG/70"
                      }`}
                      onClick={() => HandleSubMenu(e.name)}
                      key={e.id}
                    >
                      {e.name}
                      {e.name === "Symbols / pricing" && (
                        <SvgComponent
                          svgName={subMenu ? "CaretDown" : "CaretRight"}
                          className={"h-3 w-3 fill-border/50"}
                        />
                      )}

                    </div>
 {subMenu && e?.name === "Symbols / pricing" &&  <div className={`w-full ${openschedulerSlice ? "h-64" : "h-32"}`}>
  {subMenu && (
              <div className="h-32 w-full bg-white flex px-5   flex-col">
                {Object.keys(subTab[0]).map((key) => (
                  <div className={` flex  flex-col`} key={key}>
                    <Link to={`symbols-pricing/${key}`}>
                    <div
                      onClick={() => {
                        dispatch(realSubTab(key));
                        setOpenschedulerSlice(!openschedulerSlice);
      }}
                      className={`h-10 w-full px-5  flex items-center border-b border-b-gray  ${
                        SubSliceTab === key ? "bg-primaryColor/10" : ""
                      }`}
                    >
                      <span
                        className={`text-14 w-full text-left ${
                          SubSliceTab === key
                            ? "text-primaryColor font-bold"
                            : "font-semibold"
                        }`}
                      >
                        {key.toUpperCase()}
                      </span>
                      <SvgComponent
                        svgName={
                          openschedulerSlice && SubSliceTab === key
                            ? "CaretDown"
                            : "CaretRight"
                        }
                        className={`h-3 w-3 fill-border/50 `}
                      />
                    </div>
                    </Link>
                    {openschedulerSlice &&
                      SubSliceTab === key &&
                      subTab[0].asx.map((item) => (
                        <div
                          className={`h-8 w-60 mt-3 flex flex-col self-center items-start px-4 p-1 justify-between text-13 pt-1.5  text-black rounded-full ${
                            item.Routine === schedulerSlice
                              ? "bg-primaryColor text-white cursor-pointer"
                              : ""
                          }`}
                          key={item.Routine}
                          onClick={() =>{  dispatch(DWMTab(item.Routine))}}
                        >
                          {" "}
                          {"- " + item.Routine}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}
  </div>}
                 </Link>
                </li>
              </ul>
            ))}
            
          </div>
        </div>
        <div className="flex flex-col w-4/5">
          {jobDownloading &&
            <Alert message={jobDownloadingMessage} />
          }
          <Routes>
          {/* <Route
            exact
            path="/"
            element={
              mainSliceTab === "Symbols / pricing" ? (
                <>
                  <Navigate to={`symbols-pricing/${SubSliceTab}`} />
                  <ASX /> 
                </>
              ) : (
                <Navigate to={`/${mainSliceTab}`} />
              )
            }
          />
              <Route
                exact
                path={`/symbols-pricing/${SubSliceTab}`}
                element={<ASX />}
              />
              <Route
                exact
                path={`/symbols-pricing/${SubSliceTab}`}
                element={<MarketNasdaq />}
              />
              <Route
                exact
                path={`/symbols-pricing/${SubSliceTab}`}
                element={<MarketNyse />}
              /> */}

              <Route exact path="/market-alert" element={<ContentPage />} />
              <Route exact path="/market-heat" element={<ContentPage />} />
              <Route exact path="/heat-fives" element={<ContentPage />} />
              <Route exact path="/stock-lists" element={<ContentPage />} />
              <Route exact path="/premium-lists" element={<ContentPage />} />
              <Route exact path="/watchlist" element={<ContentPage />} />
              <Route exact path="/stock-alert" element={<ContentPage />} />
              {/* <Route exact path="/report" element={<ContentPage />} /> */}

              {/* <Route exact path="/data-job" element={<DataJobs />} />
              <Route exact path="/all-job" element={<AllJobs />} /> */}
              <Route exact path="/job-reporting" element={<JobReporting />} />
              <Route exact path="/add-indices" element={<AddIndices />} />
              <Route exact path="/global-settings" element={< GlobalSettings/>} />
              <Route path="*" element={<NotFound />} />
              {/* <Route exact path="/email-template" element={<EmailTemplate />} /> */}
              </Routes>
        </div>
      </div>
    
  );

}
