import { configureStore } from '@reduxjs/toolkit';
import selectedTabReducer from './Slice/MainTab'; 
import SelectedSubTab from './Slice/SubTab'
import SelectedDWMTab from './Slice/DWM'
import AuthSlice from './Slice/Token'
import TableSlice from './Slice/table';


const store = configureStore({
  reducer: {
    selectedTab: selectedTabReducer,
          SelectedSubTab : SelectedSubTab, 
          SelectedDWMTab : SelectedDWMTab,
          auth:AuthSlice,
          table:TableSlice
          
     },
});

export default store;
