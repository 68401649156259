export const API_LIST = {
  systemApi: process.env.REACT_APP_SYSTEM_API || "https://api.marketalertpro.com/",
  coreApi: process.env.REACT_APP_CORE_API || "https://core.marketalertpro.com/api/v1",
  
  // systemApi: "http://192.168.0.104:4000/",
  // systemApi: "http://192.168.100:23:4000/",
  // coreApi:"http://192.168.100.3:5124/api/v1",
  // jobs: "https://api.marketalertpro.com/admin"
  // jobs: "http://api.marketalertpro.com/admin"
};
