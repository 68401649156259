import React from 'react'
import PageTitle from '../../Components/PageTitle'
import LogoutBtn from '../../Components/LogoutBtn'
import { useState } from 'react'
import { SvgComponent } from '../../Components/SvgComponent'
import { ButtonComp } from '../../Components/Utils'
import { toast } from 'react-toastify'
import jobs from '../../api/services/jobs'

const GlobalSettings = () => {
    const [email, setEmail] = useState('')
    const [loading,setLoading] = useState(false)
    const [emailList, setEmailList] = useState([])
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const handleRemoveEmail = (item)=>{
      let filterItem = emailList?.filter((val)=>val !== item)
      setEmailList(filterItem)    
    }
    
    const handleSave = async()=>{
      setLoading(true)
      const payload = {
        email:emailList
      }
      const res = await jobs.globalEmail(payload)
      if(res?.data?.success){
        toast.success(res?.data?.message)
      }else{
        toast.error(res?.data?.message)
      }
      setLoading(false)
    }

    const handleAddEmail = ()=>{
        const isValidEmail = emailRegex.test(email);
        if(isValidEmail){
          const isEmailPresent = emailList?.some((item)=>item==email)
          if(isEmailPresent){
            toast.error("Email already present")
          }else{
            const newArr = [...emailList, email];
            setEmailList(newArr)
            setEmail('')
          }
        }else{
          toast.error("Write valid email")
        }
      }
      const handleKeyPress = (e)=>{
        if (e.key === 'Enter') {
          handleAddEmail();
        }
      }

    return (
      <div className="flex w-full h-full  overflow-hidden px-8">
      <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
      <div className="flex flex-col">
      <div className="w-full flex items-center justify-between">
            <PageTitle title="Global Settings"/>
            <LogoutBtn />
          </div>
      </div>
        
      <div className='bg-white space-y-4 px-2 py-4 mt-12'>
        <div className='w-4/12'>
        <div className='text-16 font-bold text-black/60'>Add Email</div>
          <div className='flex space-x-2 items-center'>
          <input
            placeholder="write email and press enter"
            className="h-12 w-full px-2 mt-2 place-items-center placeholder:text-black/50 text-black/70 text-16 bg-transparent border border-gray/40 hover:border-gray rounded-md placeholder-border outline-none"
            type="text"
            value={email}
            onKeyPress={handleKeyPress}
            // autoComplete={false}
            onChange={(e)=>setEmail(e.target.value)}
            />

            <div title="Add Email" className={`${email ? '' : 'pointer-events-none opacity-60'} p-2 rounded-full bg-lightgreen/10 hover:bg-lightgreen/20 cursor-pointer mt-1`} onClick={handleAddEmail}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 text-primaryColor h-5">
             <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>

            </div>
          </div>
        </div>

        <div className='w-10/12'>
          <div className='text-16 font-bold text-black/60'>Emails</div>
          <div className="h-44 p-2 flex flex-wrap overflow-y-auto w-full space-x-4 cursor-auto px-2 mt-2 text-16 bg-transparent border border-gray/40 hover:border-gray rounded-md placeholder-border outline-none">
            {emailList?.length ? emailList?.map((item,i)=>(
              <div key={i} className='flex py-1 px-2 bg-primaryColor/10 space-x-1 w-fit bg-transparent items-center h-fit'>
                 <div className='text-black/70'>{item}</div> 
              
                 <div title='remove' onClick={()=>handleRemoveEmail(item)} className='cursor-pointer hover:bg-red/5 rounded-full'>
                    <SvgComponent svgName="cross" className="w-4 h-4 mt-1 text-red" />
                 </div>
                 
              </div>
            ))
              :
              <div className='w-full h-full flex items-center justify-center text-black/40'>No Email Found</div>
          }
          </div>
        </div>

        <div className='flex justify-end w-10/12'>
        <ButtonComp text={loading?"Saving":"Save"} BtnClass={`h-10 w-2/12 ${loading?'pointer-events-none':''} ${emailList?.length > 0 ?'':'pointer-events-none opacity-70'}`}  TextClass='text-16 tracking-wider' onClick={handleSave}/>
        </div>
      </div>
        
      </div>
  </div>
    )
}

export default GlobalSettings


