import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonComp, TableComponent } from "../../Components/Utils";
import Heat_Fives from "../../Components/Modal/Edit";
import BaseModal from "../../Components/Modal/BaseModal";
import LogoutBtn from "../../Components/LogoutBtn";
import EditModal from "../../Components/Modal/Edit/EditModal";
import service from "../../api/services/jobs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { list, jobsData } from "./utils";
import { downloadSetter, downloadMessageSetter } from "../../Store/Slice/table";
import { useDispatch } from "react-redux";
import Refresh from "../../Components/Modal/Refresh";
import TimeFormat from "../../Components/TimeFormat";
import { getCountry } from "../../Components/Utils";
import Verify from "../../Components/Modal/Verify";
import jobs from "../../api/services/jobs";
import PageTitle from "../../Components/PageTitle";

export default function ContentPage() {
  const dispatch = useDispatch();
  const highLight = useSelector((state) => state.selectedTab.initialState);
  const [currentTab, setCurrentTab] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const jobLoading = useSelector((state) => state.table.downloadLoading);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const header = ["Run At", "Country"];
  const [data, setData] = useState([]);
  const location = useLocation();
  const [refreshRow, setRefreshRow] = useState(null);
  const [refreshEmail, setRefreshEmail] = useState("admin@marketalertpro.com");

  useEffect(() => {
    const path = location?.pathname?.substring(1);
    const selectedData = list?.find((item) => item?.name == path);
    setCurrentTab(selectedData);
  }, []);

  useEffect(() => {
    const path = location?.pathname?.substring(1);
    const selectedData = list?.find((item) => item?.name == path);
    setCurrentTab(selectedData);
  }, [location?.pathname]);

  useEffect(() => {
    if (selectedRow) {
      setOpenEditModal(true);
    }
  }, [selectedRow]);

  useEffect(() => {
    console.log("current Tab", currentTab);
    getJobData();
  }, [currentTab]);

  const getJobData = async () => {
    setLoading(true);
    const res = await service.getJobData();
    console.log("Response from getJobData ==> :", res); // Add this line
    if (res?.length > 0) {
      const result = res?.filter(
        (item) => item?.jobDetails?.type == currentTab?.type
      );
      if (currentTab?.type == "stocklist_beta") {
        result?.sort((a, b) => {
          const propertyA = a.jobDetails?.marketId;
          const propertyB = b.jobDetails?.marketId;

          if (propertyA < propertyB) {
            return -1;
          }
          if (propertyA > propertyB) {
            return 1;
          }

          return 0;
        });
        setData(result);
      } else {
        setData(result);
      }
    } else {
      setData([]);
    }
    setLoading(false);
  };

  const handleEdit = (item) => {
    setSelectedRow(item);
  };

  const handleStatus = async (val, item) => {
    console.log("status");
    const payload = {
      status: !val,
      id: item?._id,
    };
    const res = await service.updateStatus(payload);
    if (res?.data?.success) {
      toast.success(`Status updated successfully`);
      getJobData();
      return true;
    } else {
      toast.error("Some error occured while updating status");
      return false;
    }
  };

  const handleDelete = (item) => {
    console.log("delete", item);
  };

  const handleCloseEdit = () => {
    setSelectedRow(null);
    setOpenEditModal(false);
  };

  const handleUpdate = async (payload) => {
    const res = await service.updateConfig(payload);
    if (res?.data?.success) {
      toast.success("Data updated successfully");
      handleCloseEdit();
      getJobData();
    } else {
      toast.error("Error occured while updating data");
    }
  };

  const handleDownloadJob = async (item,isRefresh=false) => {

    const message = `${currentTab?.message} job for ${getCountry(
      item?.jobDetails.marketId
    )} is under process, you can not run other jobs before its completion.`;
    dispatch(downloadMessageSetter(message));
    dispatch(downloadSetter(true));
    const filterRow = jobsData?.find((x) => x?.key == item?.jobDetails?.type);
    if (filterRow) {
      let url =
        filterRow?.name == "pluslist"
          ? `${filterRow?.endPoint}/${item?.jobDetails?.marketId}/today`
          : `${filterRow?.endPoint}/${item?.jobDetails?.marketId}`;

      if(filterRow?.name == 'topfive' && !isRefresh){
        url = `${filterRow?.endPoint}/${item?.jobDetails?.marketId}?${filterRow?.queryParam}`;
      }

      let res = await service.downloadJobs(url);

      if (res) {
        if (!res?.data?.isError) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } else {
        toast.error("Error occured while downloading job");
      }
    }
    dispatch(downloadSetter(false));
    dispatch(downloadMessageSetter(""));
  };

  const handleRefresh = (item) => {
    setRefreshRow(item);
    setRefreshModal(true);
  };

  const handleCloseRefresh = () => {
    setRefreshModal(false);
  };

  const saveRefresh = () => {
    // toast.success("This feature is under development")
    handleCloseRefresh();
    setIsVerify(true);
  };

  const handleVerify = () => {
    setIsVerify(false);
    toast.success("Code verified Successfully!");
    handleDownloadJob(refreshRow,true);
  };

  return (
    <div className="flex w-full h-full  overflow-hidden px-8">
      {open && (
        <BaseModal>
          <Heat_Fives StateName={open} setStated={setOpen} header={highLight} />
        </BaseModal>
      )}

      {openEditModal && (
        <BaseModal>
          <EditModal
            selectedData={selectedRow}
            handleClose={handleCloseEdit}
            handleUpdate={handleUpdate}
            currentTab={currentTab}
          />
        </BaseModal>
      )}

      {refreshModal && (
        <BaseModal>
          <Refresh
            handleClose={handleCloseRefresh}
            email={refreshEmail}
            handleSave={saveRefresh}
            currentTab={currentTab}
          />
        </BaseModal>
      )}

      {isVerify && (
        <BaseModal>
          <Verify
            handleClose={() => setIsVerify(false)}
            email={refreshEmail}
            handleSave={handleVerify}
          />
        </BaseModal>
      )}

      <div className="w-full h-full bg-white text-black text-left font-semibold text-28">
        <div className="flex flex-col">
          <div className="w-full flex items-center justify-between">
            <PageTitle title={currentTab?.heading} />
            <LogoutBtn />
          </div>
          {/* <span className="text-10 text-black/40 ">
            {highLight?.toUpperCase()}
          </span> */}
        </div>

        <div className="bg-white px-2 py-4 space-y-4">
          {data?.length > 0 && (
            <div className="w-full flex justify-end">
              <TimeFormat />
            </div>
          )}
          <div className="">
            <TableComponent
              header={header}
              handleRefresh={handleRefresh}
              downloadJobs={handleDownloadJob}
              jobLoading={jobLoading}
              data={data}
              loading={loading}
              handleStatus={handleStatus}
              editClick={handleEdit}
              deleteClick={handleDelete}
              currentTab={currentTab?.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
