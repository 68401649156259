import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { JobReportingTable} from '../../Components/Utils';
import LogoutBtn from '../../Components/LogoutBtn';
import BaseModal from '../../Components/Modal/BaseModal';
import AllJobEditModal from '../../Components/Modal/Edit/AllJobEditModal';
import service from '../../api/services/jobs'
import {toast} from 'react-toastify'
import TimeFormat from '../../Components/TimeFormat';
import PageTitle from '../../Components/PageTitle';

export default function JobReporting() {
    const highLight = useSelector((state) => state.selectedTab.initialState);
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const [limit,setLimit] = useState(50)
    const header = ["Market Id","Job Type","Status","Message","Run At","Complete At"]

    useEffect(()=>{
        getAllJobData()
    },[])
    
    useEffect(()=>{
      getAllJobData()
    },[limit])

    const getAllJobData = async()=>{
        setLoading(true)
        const res = await service.ftpJobReporting(limit)
        if(res?.data?.payload?.length){
          let updatedData = res?.data?.payload?.filter(item => item?.jobDetails?.type != 'report' && item?.jobDetails?.type != 'stockalert_company_report')
            setData(updatedData)
        }else{
            setData([])
        }
        setLoading(false)
    }

  

  

return (
    <div className="flex w-full h-full  overflow-hidden px-8">
       


        <div className="w-full h-full bg-white text-black text-left font-semibold text-28  ">
            
        <div className="flex flex-col">
          <div className="w-full flex items-center justify-between">
            <PageTitle title="Job Reporting Configuration" />
          
          <LogoutBtn />
          </div>
        
        </div>
          
        <div className='bg-white px-2 py-6 space-y-4'>
          {data?.length > 0 &&
            <div className='w-full flex items-center justify-between'>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="block bg-white border border-gray focus:outline-none text-black/70 text-14 p-1 pr-4 rounded-md"
                >
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>ALL</option>
                </select>
              </div>
              <div>
                <TimeFormat />
              </div>
          </div>}
          <div className=''>
            <JobReportingTable header={header} data={data} loading={loading} />
          </div>
        </div>
          
        </div>
    </div>
);
}
