import React,{useEffect, useState} from 'react'
import { ButtonComp } from '../../Utils'
import { SvgComponent } from '../../SvgComponent'
import {toast} from 'react-toastify'

function AddIndicesModal({loading,setIsSelected,handleUpdate=()=>{}, isEdit,indiceCustomName,selectedCountry,setSelectedCountry,setIndiceCustomName,handleSave=()=>{},handleClose=()=>{},setIndiceName,indice,indiceLoading,indicesList=[],setIndicesList,setExchange}) {
  const countryData = [{value:"us",name:"United States",exchange:"US Indices"},{value:"oz",name:"Australia",exchange:"AU Indices"},{value:"ca",name:"Canada",exchange:"CA Indices"}]
  // const [indiceCustomName,setIndiceCustomName] = useState('')
  // const [selectedCountry,setSelectedCountry] = useState(null)
  const [selectedIndice,setSelectedIndice] = useState(null)
  const [showList,setShowList] = useState(false)
  const [isSelect,setIsSelect] = useState(false)
 

  useEffect(()=>{
    if(isEdit){
      setIsSelected(true)
      handleCountryChange(selectedCountry)
      setIndiceName(indice)
    }
  },[isEdit])

  const onSave = ()=>{
    if(selectedIndice){
      if(isSelect){
        handleSave(selectedIndice) 
      }else{
        toast.error("Select valid index from dropdown list")
      }
    }else{
      toast.error("All Fields are required")
    }
  }


  const handleSelectIndice = (item)=>{
    setSelectedIndice(item)
    setIndiceName(item?.symbol)
    setIsSelect(true)
    setIsSelected(true)
    setShowList(false)

  }

  const handleChange = (e)=>{
    console.log("e",e)
    setIndiceName(e)
    setShowList(true)
  }

  const handleCountryChange = (val)=>{
    if(val == "abc"){
      setSelectedCountry('')
      setExchange('')
    }else{
      setSelectedCountry(val)
      setIndiceName('')
      const findExchange = countryData?.find((item)=>item?.value == val)
      setExchange(findExchange.exchange)
    }
  }

  const handleKeyDown = (event)=>{
    if (event.key === 'c' || event.key === 'C' || event.ctrlKey ||event.key === 'A'||event.key === 'a') {
      return
    } else {
      setIsSelect(false);
      setIsSelected(false);
    }
  }


  return (
    <div className='w-[35vw] h-auto overflow-y-auto max-h-[70vh] space-y-2 p-2'>
    <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
        <div className='text-24 text-black/50'></div>
        <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
            <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
        </div>
    </div>
    <div className='w-full h-[45vh] relative space-y-4 py-4 px-2'>
    <select id="country" onChange={(e) => handleCountryChange(e.target.value)} value={selectedCountry} className={`h-12 text-black/70 w-full hover:border-[#2d2d2d]/20 px-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none `}>
              <option selected value="abc">Choose a country*</option>
              {countryData?.map((item,i)=>(
                <option value={item?.value}>{item?.name}</option>
              ))} 
             
      </select>


      <div>
          <input
            placeholder="Search For Indices*"
            className={`${selectedCountry ? '':'pointer-events-none bg-gray'} h-12 w-full px-2 place-items-center hover:border-[#2d2d2d]/20 text-black/80 text-16 bg-transparent border border-gray rounded-md outline-none`}
            type="text"
            value={indice}
            onKeyDown={handleKeyDown}
            onChange={(e)=>handleChange(e.target.value)}
            />
      </div>

      {(indicesList?.length > 0 && indice && showList) &&
      <div className='max-h-[30vh] h-auto absolute z-60 bg-[whitesmoke] w-[98%] top-32 border border-gray rounded-md overflow-y-auto'>
        {indicesList?.map((item,i)=>(
        <div onClick={()=>handleSelectIndice(item)} className='border-b text-14 cursor-pointer space-x-4 text-black/80 items-center w-full justify-between hover:bg-gray/40 flex border-gray p-2'>
          <div className='w-2/12'>{item?.symbol}</div>
          <div className='w-8/12 text-center' title={item?.company_name}>{item?.company_name?.substring(0,30)}{item?.company_name?.length > 30&&'...'}</div>
          <div className='w-2/12 flex items-center justify-end'>{item?.exchange}</div>
        </div>
        ))}
      </div>
      }


    

      <input
          className={`w-full text-16 text-black/70 focus:outline-none border hover:border-[#2d2d2d]/20 border-gray rounded  resize-none p-3 ${selectedIndice?.symbol || indice ? '' : 'pointer-events-none bg-gray'} `}
          placeholder="Enter your desire indice name*"
          onChange={(e) => setIndiceCustomName(e.target.value)}
          value={indiceCustomName}
        />








    </div>
    <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
      <ButtonComp text={isEdit ? `${loading ? "Updating":"Update"}` : `${loading ? "Saving":"Save"}`} BtnClass={`h-10 w-2/12 ${loading?'pointer-events-none':''}`} TextClass='text-16 tracking-wider' onClick={isEdit ? handleUpdate : onSave}/>
      <ButtonComp text={"Cancel"} btnColor="bg-[gray] hover:bg-[gray]/70" BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleClose}/>
    </div>
</div>
  )
}

export default AddIndicesModal