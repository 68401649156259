import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { SvgComponent } from '../../SvgComponent'
import { TextInput, ButtonComp } from '../../Utils'


function EditModal({ handleClose = () => { }, selectedData = {}, handleUpdate = () => { }, currentTab }) {
  const [editData, setEditData] = useState(selectedData)
  const [time, setTime] = useState(selectedData?.runAt)
  const [fileDetails, setFileDetails] = useState(editData?.fileDetails)
  const [totalFiles, setTotalFiles] = useState(editData?.fileDetails[0]?.fileList?.length)
  const [emailList, setEmailList] = useState(editData?.email)
  const [email, setEmail] = useState('')
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  useEffect(() => {
    if (currentTab.name == "premium-lists") {
      const totalFileListLength = editData?.fileDetails?.reduce((totalLength, detail) => {
        return totalLength + (detail.fileList?.length || 0);
      }, 0);
      setTotalFiles(totalFileListLength);
    }
  }, []);

  const getCountry = (val) => {
    if (val == 'oz' || val == "aus") {
      return 'Australia'
    } else if (val == "ca" || val == "can") {
      return 'Canada'
    } else if (val == "us") {
      return 'United State'
    } else if (val == "master") {
      return 'Australia'
    } else if (val == "masterUS") {
      return 'US/Canada'
    }
    else {
      return val
    }
  }

  const handleEdit = () => {
    var filePaths = fileDetails.map(x => x.filePath);
    const payload = {
      time,
      filePath: filePaths,
      // filePath: currentTab?.name == "premium-lists" ? filePaths : [file1],
      id: selectedData?._id,
      emails: emailList
    }
    handleUpdate(payload)
  }

  const handleAddEmail = () => {
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
      const newArr = [...emailList, email];
      setEmailList(newArr)
      setEmail('')
    } else {
      toast.error("Write valid email")
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddEmail();
    }
  }

  const handleRemoveEmail = (val) => {
    if (emailList?.length > 1) {
      const filterData = emailList?.filter((item) => item != val)
      setEmailList(filterData)
    } else {
      toast.error("You should have atleast one email")
    }
  }

  return (
    <div className='w-[40vw] h-auto overflow-y-auto max-h-[70vh] space-y-2 p-2'>
      <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
        <div className='text-24 text-black/50'></div>
        <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
          <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
        </div>
      </div>
      <div className='w-full space-y-4 py-4 px-2 h-full'>
        <div className='w-full flex flex-wrap'>
          <div className='w-full sm:w-1/2 lg:w-1/2 xl:w-1/2 p-2'>
            <div className='text-16 font-bold text-black/60'>Run Time (UTC)</div>
            <input
              placeholder="Run time"
              className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              autoComplete={false}
            />
          </div>

          {
            fileDetails && fileDetails.map((file, index) => (
              <div className='w-full sm:w-1/2 lg:w-1/2 xl:w-1/2 p-2'>
                <div className='text-16 font-bold text-black/60'>File Path {fileDetails.length != 1 && index + 1}</div>
                <input
                  placeholder="File path"
                  className="h-12 w-full px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
                  type="text"
                  value={file.filePath}
                  autoComplete={false}
                  onChange={(e) => {
                    const updatedFileDetails = [...fileDetails]; // Create a new array copy
                    updatedFileDetails[index] = { ...file, filePath: e.target.value }; // Update the filePath in the copied array
                    setFileDetails(updatedFileDetails); // Set the state with the new array
                    //setFile1(e.target.value)
                  }}
                />
              </div>
            ))
          }

        </div>

        <div className='w-full flex items-center space-x-4'>
          <div className='w-6/12'>
            <div className='text-16 font-bold text-black/60'>Country</div>
            <input
              placeholder="Country"
              className="h-12 w-full px-2 cursor-not-allowed  mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
              type="text"
              // onChange={onChange}
              disabled
              value={getCountry(selectedData?.jobDetails?.marketId)}
              autoComplete={false}
            />
          </div>
          <div className='w-6/12'>
            <div className='text-16 font-bold text-black/60'>Total Files</div>
            <input
              placeholder="Total files"
              className="h-12 w-full cursor-not-allowed px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
              type="text"
              // onChange={onChange}
              value={totalFiles}
              autoComplete={false}
              disabled
            />
          </div>


        </div>

        <div className='w-[55%]'>
          <div className='text-16 font-bold text-black/60'>Add Email</div>
          <div className='flex space-x-2 items-center'>
            <input
              placeholder="write email and press enter"
              className="h-12 w-full px-2 mt-2 place-items-center placeholder:text-black/50 text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
              type="text"
              value={email}
              onKeyPress={handleKeyPress}
              // autoComplete={false}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div title="Add Email" className={`${email ? '' : 'pointer-events-none opacity-60'} p-2 rounded-full bg-lightgreen/10 hover:bg-lightgreen/20 cursor-pointer mt-1`} onClick={handleAddEmail}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 text-primaryColor h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>

            </div>
          </div>
        </div>


        <div className='w-full'>
          <div className='text-16 font-bold text-black/60'>Emails</div>
          <div className="h-32 p-2 flex flex-wrap overflow-y-auto w-full space-x-4 cursor-auto px-2 mt-2 text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none">
            {emailList?.map((item, i) => (
              <div className='flex py-1 px-2 bg-primaryColor/10 space-x-1 w-fit bg-transparent items-center h-fit'>
                <div>{item}</div>
                <div title='remove' onClick={() => handleRemoveEmail(item)} className='cursor-pointer hover:bg-red/5 rounded-full'>
                  <SvgComponent svgName="cross" className="w-4 h-4 mt-1 text-red" />
                </div>
              </div>
            ))}
          </div>
        </div>



      </div>
      <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
        <ButtonComp text={"Update"} BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleEdit} />

        <ButtonComp text={"Cancel"} btnColor="bg-[gray] hover:bg-[gray]/70" BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleClose} />

      </div>
    </div>
  )
}

export default EditModal