import React,{useState,useEffect} from 'react'
import { SvgComponent } from '../../SvgComponent'
import { TextInput,ButtonComp } from '../../Utils'
import Alert from '../../Alerts/Alert'
import { toast } from 'react-toastify'
import jobs from '../../../api/services/jobs'

function Verify({handleClose=()=>{},handleSave=()=>{},email}) {
    const [code,setCode] = useState('')
    const [loading,setLoading] = useState(false)
    
    const onSave = async()=>{
      setLoading(true)
        const payload={
            code:code,
            email:email
          }
          const res = await jobs.sendVerifyCode(payload)
          if(res?.data?.success){
            // toast.success(res?.data?.message)
            handleSave()
          }else{
            toast.error(res?.data?.message)
          }
      setLoading(false)

    }

    const handleKeyPress = (event)=>{
      if (event.key === 'Enter') {
        onSave()
      }
    }
  return (
    <div className='w-[30vw] h-auto overflow-y-auto max-h-[60vh] space-y-2 p-2'>
        <div className='w-full pl-2 border-b border-gray flex items-center justify-between h-[8%]'>
            <div className='text-24 text-black/50'></div>
            <div onClick={handleClose} className='p-2 hover:bg-black/20 rounded-full cursor-pointer'>
                <SvgComponent svgName="cross" className="w-5 h-5 text-black/60" />
            </div>
        </div>
        <div className='w-full space-y-4 py-4 px-2 h-full'>
    <Alert message={`We just sent you code on ${email}`} textColor='text-[green]/50' bgColor='bg-[green]/10' />
     <div className='w-9/12'>
          {/* <div className='text-16 font-bold text-black/60'>Total Files</div> */}
          <input
            placeholder="Enter code*"
            className="h-12 w-full hover:border-[#2d2d2d]/20 text-black/80 tracking-wider px-2 mt-2 place-items-center text-16 bg-transparent border border-gray rounded-md placeholder-border outline-none"
            type="text"
            maxLength="6"
            value={code}
            autoComplete={false}
            onChange = {(e)=>setCode(e.target.value)}
            onKeyPress={handleKeyPress}
            />
          </div>
          </div>


        <div className='w-full pl-2 border-t py-2 pr-2 border-gray space-x-6 flex items-center justify-end h-[8%]'>
          <ButtonComp text={loading ? "Refreshing" : "Refresh"} BtnClass={`h-10 ${loading?'w-3/12':'w-2/12'}  ${code ? '':'pointer-events-none opacity-60'}`} TextClass='text-16 tracking-wider' onClick={onSave}/>

          <ButtonComp text={"Cancel"} btnColor="bg-[gray] hover:bg-[gray]/70" BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider' onClick={handleClose}/>
          
        </div>
    </div>
  )
}

export default Verify