export const list = [
  {
    name: "premium-lists",
    message: "Premium Lists",
    heading: "Premium Lists Job Configuration",
    type: "fives",
  },
  {
    name: "market-heat",
    message: "Market Heat",
    heading: "Market Heat Job Configuration",
    type: "heat",
  },
  {
    name: "heat-fives",
    message: "Heat Fives",
    heading: "Heat Fives Job Configuration",
    type: "top_five",
  },
  {
    name: "stock-lists",
    message: "Stock Lists",
    heading: " Stock Lists Job Configuration",
    type: "stocklist_beta",
  },
  {
    name: "market-alert",
    message: "Market Alert",
    heading: "Market Alert Job Configuration",
    type: "watch",
  },
  {
    name: "watchlist",
    message: "Watchlist",
    heading: "Watchlist Job Configuration",
    type: "watchlist_detail",
  },
  {
    name: "stock-alert",
    message: "Stock Alert",
    heading: "Stock Alert Configuration",
    type: "stock_alert",
  },
  // {
  //   name: "report",
  //   message:"Company Reports",
  //   heading: "Company Reports Configuration",
  //   type: "report",
  // },
];

export const jobsData = [
  { name: "marketalert", endPoint: "download-market-watch-data", key: "watch" },
  { name: "marketheat", endPoint: "download-market-heat-data", key: "heat" },
  { name: "topfive", endPoint: "download-topfive", key: "top_five",queryParam:"latest=true" },
  {
    name: "watchlist",
    endPoint: "download-watchlist-data",
    key: "watchlist_detail",
  },
  {
    name: "pluslist",
    endPoint: "download-stocklist-beta",
    key: "stocklist_beta",
  },
  { name: "premiumlist", endPoint: "download-fives-data", key: "fives" },
  { name: "report", endPoint: "download-report-data", key: "report" },
  {
    name: "stockAlert",
    endPoint: "download-stock-alert-data",
    key: "stock_alert",
  },
];
