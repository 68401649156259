
import './App.css';

import Navigator from "./Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App">
    
      <Navigator/> 
      <ToastContainer />

    </div>
  );
}

export default App;
