import axios from 'axios'
import { API_LIST } from '../config'

export default {
    getSymbolData : async (payload)=>{
        try {
            const res = await axios.get(`${API_LIST.coreApi}/symbols/startWithNew/${payload.symbol}/true/${payload.exchange}`)  
            if(res?.status === 200){
                return res?.data?.data
            }else{
                return false
            }           
        } catch (error) {
            return false
        }
    },
    
  
}