import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'

function NotFound() {
  const navigate = useNavigate()
  const UserToken = useSelector((state) => state.auth.token);
  const token = localStorage.getItem('token')

   
  useEffect(() => {
    if(UserToken || token){
     return
    }else{
     navigate('/login')
    }
   }, [])
   
  return (
    <div className='w-full h-[80vh] text-black/60 text-24 flex items-center justify-center'>
        Page Not Found
    </div>
  )
}

export default NotFound