import { createSlice } from "@reduxjs/toolkit";
export const TableSlice = createSlice({
  name: "table",
  initialState: {
   formatTime:"UTC",
   downloadLoading:false,
   downloadMessage:''
  },
  reducers: {
    
    timeSetter: (state, data) => {
      state.formatTime = data.payload;
    },
    downloadSetter: (state, data) => {
      state.downloadLoading = data.payload;
    },
    downloadMessageSetter: (state, data) => {
      state.downloadMessage = data.payload;
    },
  },

});

export const {timeSetter,downloadSetter,downloadMessageSetter} = TableSlice.actions;

export default TableSlice.reducer;