import React from 'react'

function Alert({message="Certain job is under process you can not run other jobs before its completion",textColor="primaryColor",bgColor="bg-lightgreen/20"}) {
  return (
    <div className={`p-4 mb-1 text-sm ${textColor} ${bgColor} rounded-lg`} role="alert">
        {message}
  </div>
  )
}

export default Alert