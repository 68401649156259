import React, { useState } from "react";
import { SvgComponent } from "../SvgComponent";
import "./index.css";
import Spinner from '../../Components/Spinner';
import moment, { utc } from 'moment'
import { useSelector } from "react-redux";
import momentTimeZone from 'moment-timezone'
import BaseModal from '../../Components/Modal/BaseModal';

const getOccurence = (t)=>{
  if(t == "D"){
    return "Daily"
  }else if(t == "W"){
    return "Weekly"
  }else if(t == "M"){
    return "Monthly"
  }else{
    return 'Not Found'
  }
}

const getFormattedTime = (dt,zone)=>{
let utcTime = momentTimeZone.utc(dt)
if(zone == 'Perth'){
  const perthTime = utcTime.clone().tz('Australia/Perth')
  return perthTime?.format('MMMM Do, YYYY h:mm A')
}else if(zone == "Sydney"){
  console.log("usa")
  const sydneyTime = utcTime.clone().tz('Australia/Sydney')
  return sydneyTime?.format('MMMM Do, YYYY h:mm A')
}else if(zone == "USA"){
  const usaTime = utcTime.clone().tz('America/New_York')
  return usaTime?.format('MMMM Do, YYYY h:mm A')
}
else{
  return utcTime?.format('MMMM Do, YYYY h:mm A')
}
}

const getRunAtMinuteFormatted = (t,zone)=>{
  const localTime = momentTimeZone.utc(t,'HH:mm')
  if(zone == 'Perth'){
    const perthTime = localTime.tz('Australia/Perth').format('HH:mm')
    return perthTime
  }else if(zone == "Sydney"){
    console.log("usa")
    const sydneyTime = localTime.clone().tz('Australia/Sydney')
    return sydneyTime?.format('HH:mm')
  }else if(zone == "USA"){
    const usaTime = localTime.clone().tz('America/New_York')
    return usaTime?.format('HH:mm')
  }
  else{
    return localTime?.format('HH:mm')
  }
}

const firstLetterCap = (val)=>{
  if(val){
    return val?.charAt(0).toUpperCase() + val?.slice(1);
  }else{
    return 'N/A'
  }
}

export const getCountry = (val)=>{
  if(val == 'oz' || val == "aus" || val=="au"){
    return 'Australia'
  }else if(val == "ca" || val == "can"){
    return 'Canada'
  }else if(val == "us"){
    return 'US'
  }else if(val == "master"){
    return 'Australia'
  }else if(val == "masterus"){
    return 'US/Canada'
  }
  else{
    return val
  }
} 


export const EventHandler = (setState) => (event) => {
  setState(event.target.value);
};

export const TextInput = ({
  label,
  value,
   Svg_Name = "",
  onChange,
  classNames,
  onClick,
  handleKeyPress,
  types,
}) => {
  return (
    <div className={`flex justify-between bg-red-600 items-center h-10 w-60 border border-border rounded ${classNames}`}>
      <input
        placeholder={label}
        className="h-full w-full place-items-center text-16 placeholder-border outline-none px-4"
        type={types}
        onChange={onChange}
        value={value}
        autoComplete={false}
        onKeyDown={handleKeyPress}
        
      />
      {Svg_Name && (
        <SvgComponent onClick={onClick} className={`w-6 h-6 text-black/40 mr-2`} svgName={Svg_Name} />
      )}
      
    </div>
  );
};

export const ButtonComp = ({
  text,
  Svg_Name = "",
  onClick,
  classNames,
  BtnClass,
  TextClass,
  btnColor="bg-primaryColor hover:bg-primaryColor/80"
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center ${btnColor} text-white space-x-2 cursor-pointer rounded  ${BtnClass}`}
    >
      {Svg_Name && (
        <SvgComponent className={`${classNames}`} svgName={Svg_Name} />
      )}
      <span className={`text-12 ${TextClass}`}>{text}</span>
    </div>
  );
};


export const TablePage = ({ data=[],loading,editClick = ()=>{},deleteClick=()=>{} }) => {
  let keys;
  if(data?.length){
    keys = Object?.keys(data[0]);
  }

  const handleEdit = (row)=>{
    editClick(row)
  }
  const handleDelete = (row)=>{
    deleteClick(row)
  }

return (
    <div className='flex flex-col h-[75vh] w-full overflow-auto'>
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {keys.map((key) => (
              
              <th
                key={key}
                className={`border-r border-gray text-16 text-black/60 }`}
              >
                {key.replace('_', ' ')}
              </th>
            ))}
            <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th>
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data.map((item, index) => (
            <tr key={index} className='border border-gray'>
              {keys.map((key) => (
                <td
                  key={key}
                  className={`border-r border-gray text-14 text-center h-12 text-black/40 `}
                >
                  {item[key]}
                </td>
              ))}
              <td className="border-r border-gray text-14 text-center h-12 text-black/40 ">
                <div  title="Edit" className="flex w-full items-center justify-center">
                  <div onClick={()=>handleEdit(item)} className="p-2 hover:bg-lightgreen/10 rounded-full cursor-pointer">
                    <SvgComponent svgName="edit" className="w-5 h-5" /> 
                  </div>
                  <div onClick={()=>handleDelete(item)} title="Delete" className="p-2 hover:bg-red/10 rounded-full cursor-pointer">
                    <SvgComponent svgName="delete" className="w-5 h-5 cursor-pointer" /> 
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}


export const TableComponent = ({ data=[],loading,editClick = ()=>{},deleteClick=()=>{},header,handleStatus=()=>{},downloadJobs=()=>{},handleRefresh=()=>{},jobLoading,currentTab="" }) => {
  const [isActive,setIsActive] = useState('')
  const timeZone = useSelector((state) => state.table.formatTime)
  const [openModal,setOpenModal] = useState(false)
  const [stockAlertDownload,setStockAlertDownload] = useState(null)
  
  let keys;
  if(data?.length){
    keys = Object?.keys(data[0]);
  }

  const handleEdit = (row)=>{
    editClick(row)
  }
  const handleDelete = (row)=>{
    deleteClick(row)
  }



  function ToggleButton({ item }) {
    const [isActive, setIsActive] = useState(item.active);
  
    const handleStatusChange = async() => {
      console.log("handleStatus")
      const val = await handleStatus(item?.active,item)
      console.log("status",val)
      if(val){
        setIsActive(!isActive)
      }
    };

    
  
    return (
      <label className="switch">
        <input
          className="switch-input"
          type="checkbox"
          onChange={handleStatusChange}
          checked={isActive}
        />
        <span className="switch-button"></span>
      </label>
    );
  } 
  
  const convertTime = (t)=>{
    if(t){
      const time12 = moment(t, 'HH:mm').format('h:mm A');
      return time12;
    }else{
      return 'N/A'
    }
  }

  const handleDownload = (x)=>{
      if(x?.jobDetails?.type=="stock_alert"){
        setStockAlertDownload(x)
        setOpenModal(true)
      }else{
        downloadJobs(x)
      }
  }

  const handleClose = ()=>{
    setStockAlertDownload(null)
    setOpenModal(false)
  }

  const handleModalDownload = ()=>{
    downloadJobs(stockAlertDownload)
    handleClose()
  }
 

return (
    <div className='flex flex-col h-[80vh] w-full overflow-auto'>
     {openModal && 
     <BaseModal>
        <div className="w-[30vw] h-[20vh] stockAlertModal p-4">
          <div className="h-[80%] leading-[30px]">
              <span className="text-18 text-[red]">Are you sure to run this job? This job takes 30 minutes to 1 hour, and during this time, you won't be able to run any other job.</span>
          </div>
          <div className="h-[20%] w-full">
              <div className="w-full h-full flex items-center justify-end space-x-4">
              <ButtonComp text={"Download"} onClick={handleModalDownload} BtnClass={`h-10 w-3/12`} TextClass='text-16 tracking-wider'/>
              <ButtonComp text={"Cancel"} onClick={handleClose} btnColor="bg-[gray] hover:bg-[gray]/70" BtnClass={`h-10 w-2/12`} TextClass='text-16 tracking-wider'/>
              </div>
          </div>
        </div>
     </BaseModal>
     }
     
     
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {header?.map((item,i)=>(
              <th key={i} className={`border-r border-gray text-16 text-black/60 }`}>{item}</th>
            ))}
            <th className={`border-r border-gray text-16 text-black/60 }`}>Status</th>
            <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th>
            {currentTab !== 'stock-alert' && <th className={`border-r border-gray text-16 text-black/60 }`}>Refresh Data</th>}
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data?.map((item, index) => (
            <tr key={index} className='border border-gray hover:bg-primaryColor/5'>
                <td className={`border-r border-gray font-bold text-14 w-32 text-center h-12 text-black/40 `}>
                  {convertTime(getRunAtMinuteFormatted(item?.runAt,timeZone))}
                </td>
                
                <td className={`border-r border-gray text-14 text-center h-12 w-32 text-black/40 `}>
                  {getCountry(item?.jobDetails?.marketId)}
                </td>
               
                <td className="border-r border-gray text-14 text-center w-32 h-12 text-black/40">
                  <ToggleButton item={item} />
            
                </td>
              <td className="border-r border-gray text-14 text-center w-32 h-12 text-black/40 ">
                <div  className="flex w-full items-center justify-center">
                  <div title="Edit"  onClick={()=>handleEdit(item)} className="p-2 hover:bg-lightgreen/10 rounded-full cursor-pointer">
                    <SvgComponent svgName="edit" className="w-5 h-5" /> 
                  </div>
                  
                  <div onClick={()=>handleDownload(item)} title="Manual Download" className={`p-2 hover:bg-lightgreen/10 rounded-full ${jobLoading ? 'pointer-events-none':'cursor-pointer'}`}>
                    {/* <SvgComponent svgName="play" className={`w-5 h-5 cursor-pointer ${jobLoading ? 'text-gray':'text-lightgreen'}`} />  */}
                    {jobLoading ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#E9E9E9" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 text-gray">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#34D126" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 text-lightgreen h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                      }
                  </div>
                 
                </div>
              </td>
              {currentTab !== 'stock-alert' && 
              <td className="border-r border-gray text-14 text-center w-32 h-12 text-black/40 ">
                <div  className="flex w-full items-center justify-center">
                <div title="Refresh Data" onClick={()=>handleRefresh(item)} className={`p-2 hover:bg-lightgreen/10 rounded-full cursor-pointer ${jobLoading?'pointer-events-none':''}`}>
                    <SvgComponent svgName="refresh" className={`w-5 h-5 ${jobLoading?'opacity-40':''}`} /> 
                  </div>
                </div>
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}



export const DataJobTable = ({ data=[],loading,deleteClick=()=>{},header }) => {
  const [isActive,setIsActive] = useState('')
  const timeZone = useSelector((state) => state.table.formatTime)
  
  
  const formattedTime = (val)=>{
  const formattedDate = moment(val).format('MMMM D, YYYY h:mm A');
  return formattedDate
  }

  const handleDelete = (row)=>{
    deleteClick(row)
  }



return (
    <div className='flex flex-col h-[80vh] w-full overflow-auto'>
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {header?.map((item,i)=>(
              <th key={i} className={`border-r border-gray text-14 text-black/60 }`}>{item}</th>
            ))}
            {/* <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th> */}
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data?.map((item, index) => (
            <tr key={index} className='border border-gray hover:bg-primaryColor/5'>
                <td className={`border-r border-gray text-14 w-32 text-center h-12 text-black/40 `}>
                  {item?.marketId?.replace("~"," ")}
                </td>
                {/* <td className={`border-r border-gray text-14 w-32 text-center h-12 text-black/40 `}>
                  {item?.timeFrame || 'N/A'}
                </td> */}
                <td className={`border-r border-gray text-14 w-32 text-center h-12 text-black/40 `}>
                  {getOccurence(item?.occurrence)}
                </td>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.logResults?.candleUpdated?.length}
                </td>
                {/* <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.logResults?.failedRequest?.length}
                </td> */}
               
                {/* <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.logResults?.candleDataNotFoundFromApi?.length}
                </td> */}
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.jobTime,timeZone)}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.lastRunAt,timeZone)}
                </td>
                {/* <td className={`border-r border-gray text-14 w-32 flex items-center justify-center text-center h-12 text-black/40 `}>
                  <div onClick={()=>handleDelete(item)} title="delete job" className="p-2 rounded-full hover:bg-red/10 cursor-pointer">
                    <SvgComponent svgName="delete" className="w-5 h-5"/>
                  </div>
                </td> */}
              
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}


export const CustomJobTable = ({ data=[],loading, editClick=()=>{} ,header }) => {
  const timeZone = useSelector((state) => state.table.formatTime)

return (
    <div className='flex flex-col h-[80vh] w-full overflow-auto'>
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {header?.map((item,i)=>(
              <th key={i} className={`border-r border-gray text-14 text-black/60 }`}>{item}</th>
            ))}
            <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th>
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data?.map((item, index) => (
            <tr key={index} className='border border-gray hover:bg-primaryColor/5'>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.jobType}
                </td>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.marketId?.replace("~"," ")}
                </td>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {getOccurence(item?.timeFrame)}
                </td>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {getOccurence(item?.occurrence)}
                </td>
                <td className={`border-r border-gray text-14 w-44 text-center h-12 text-black/40 `}>
                  {item?.status}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.jobTime,timeZone)}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.lastRunAt,timeZone)}
                </td>
                <td className={`text-14 w-44 flex items-center justify-center space-x-2 text-center h-12 text-black/40 `}>
                <div title="edit job" onClick={()=>editClick(item)} className="p-2 rounded-full hover:bg-lightgreen/10 cursor-pointer">
                    <SvgComponent svgName="edit" className="w-5 h-5"/>
                  </div>
                  {/* <div title="manual download" onClick={()=>handleManualDownload(item)} className="p-2 rounded-full hover:bg-red/10 cursor-pointer">
                    <SvgComponent svgName="download" className="w-5 h-5"/>
                  </div> */}
                </td>
              
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}


export const JobReportingTable = ({ data=[],loading,header }) => {
  const timeZone = useSelector((state) => state.table.formatTime)

  const getMessage = (message,status)=>{
    if(status == 'MISSING DATA'){
      return 'FTP folder is empty'
    }else{
      return firstLetterCap(message)
    }
  }

return (
    <div className='flex flex-col h-[80vh] w-full overflow-auto'>
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {header?.map((item,i)=>(
              <th key={i} className={`border-r border-gray text-16 text-black/60 }`}>{item}</th>
            ))}
            {/* <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th> */}
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data?.map((item, index) => (
            <tr key={index} className='border border-gray hover:bg-primaryColor/5'>
                <td className={`border-r border-gray text-14 w-32 text-center h-12 text-black/40 `}>
                  {getCountry(item?.jobDetails?.marketId)}
                </td>
                <td className={`border-r border-gray text-14 w-32 text-center h-12 text-black/40 `}>
                  {item?.jobDetails?.type == "stocklist_beta" ? 'Plus list' : item?.jobDetails?.type == "top_five" ? 'Heat five' : item?.jobDetails?.type == "watch" ? 'Market alert' : item?.jobDetails?.type == "fives" ? 'Premium list' : firstLetterCap(item?.jobDetails?.type)}
                </td>
                <td className={`border-r border-gray text-14 w-32 text-center h-12 ${(item?.status == "error" || item?.status == 'FAILED') ? 'text-red': item?.status == "MISSING DATA"? 'text-lightYellow': item?.status == "SUCCESSFUL"? 'text-lightgreen':'text-black/40'} `}>
                  {item?.status.toUpperCase()}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40`}>
                  {item?.status == "SUCCESSFUL" ? "N/A" :item?.status == "error" ? firstLetterCap(item?.errorMsg) : getMessage(item?.response?.message,item?.status)}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.runAt,timeZone)}
                </td>
                <td className={`border-r border-gray text-14 w-60 text-center h-12 text-black/40 `}>
                  {getFormattedTime(item?.completeAt,timeZone)}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}


export const IndicesTable = ({data=[],header=[],loading,onDelete=()=>{},onEdit=()=>{}})=>{


  return (
    <div className='flex flex-col h-[80vh] w-full overflow-auto'>
      {loading 
      ?
      <Spinner />
      :
      data?.length > 0 ? 
      <table className='w-full border border-gray'>
        <thead className='overflow-hidden sticky top-0 bg-white border border-gray '>
          <tr className='h-14 w-full text-center'>
            {header?.map((item,i)=>(
              <th key={i} className={`border-r border-gray text-16 text-black/60 }`}>{item}</th>
            ))}
            <th className={`border-r border-gray text-16 text-black/60 }`}>Actions</th>
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {data?.map((item, index) => (
            <tr key={index} className='border border-gray hover:bg-primaryColor/5'>
                <td className={`border-r border-gray text-16 w-32 text-center h-12 text-black/40 `}>
                  {item?.symbol}
                </td>
                <td className={`border-r border-gray text-16 w-32 text-center h-12 text-black/40 `}>
                  {item?.desc}
                </td>
                {/* <td className={`border-r border-gray text-16 w-32 text-center h-12 text-black/40 `}>
                  {getCountry(item?.country)}
                </td> */}
                <td className={`border-r border-gray text-16 w-24 text-center h-12 text-black/40 `}>
                  <div className="flex items-center justify-center h-full">
                    <span className="p-2 cursor-pointer rounded-full hover:bg-lightgreen/10">
                      <SvgComponent svgName="edit" className="w-5 h-5" onClick={()=>onEdit(item)}/>
                    </span>
                    <span className="p-2 rounded-full cursor-pointer hover:bg-red/10">
                      <SvgComponent svgName="delete" className="w-5 h-5" onClick={()=>onDelete(item)}/>
                    </span>
                  </div>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div className="w-full text-black/40 h-full flex items-center justify-center">
        No Data Found
      </div>
      }
    </div>
  );
}