import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
   token:null,
   formatTime:"UTC"
  },
  reducers: {
    tokenSetter: (state, data) => {
      state.token = data.payload;
    },
    timeSetter: (state, data) => {
      state.formatTime = data.payload;
    },
  },

});

export const {tokenSetter,timeSetter} = AuthSlice.actions;

export default AuthSlice.reducer;