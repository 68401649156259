import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {tokenSetter} from '../../Store/Slice/Token'

function LogoutBtn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
  const handleLogout = ()=>{
    localStorage.clear()
    dispatch(tokenSetter(false))
    navigate('/login')
  }  

  return (
    <div onClick={handleLogout} title="logout" className='p-2 hover:bg-lightgreen/10 rounded-full' >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer text-black/60 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
        </svg>
    </div>
  )
}

export default LogoutBtn