import React, { useEffect, useState} from "react";
import { BrowserRouter as  Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Login from "../Pages/Login";
import RightPan from "../Components/RightPan";
import NotFound from "../Pages/NotFound";

export default function RoutesNavigation() {
  const token = localStorage.getItem('token')
  const UserToken = useSelector((state) => state.auth.token);


  return (
    
    <Router>
      <div>
        {(UserToken || token) ? <RightPan />:
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>}
    

    
    </div>
    </Router>
    

  );
}