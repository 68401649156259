import React,{useState,useEffect} from 'react'
import LogoutBtn from '../../Components/LogoutBtn'
import { ButtonComp } from '../../Components/Utils'
import { IndicesTable } from '../../Components/Utils'
import BaseModal from '../../Components/Modal/BaseModal'
import symbol from '../../api/services/symbol'
import AddIndicesModal from '../../Components/Modal/AddIndicesModal'
import jobs from '../../api/services/jobs'
import { toast,ToastContainer } from 'react-toastify'
import PageTitle from '../../Components/PageTitle'
import DeleteIndice from '../../Components/Modal/DeleteIndice'

function AddIndices() {
    const [indiceModal,setIndiceModal] = useState(false)
    const header = ["Index","Description"]
    const [indiceCustomName,setIndiceCustomName] = useState('')
    const [indiceLoading,setIndiceLoading] = useState(false)
    const [openDeleteModal,setOpenDeleteModal] = useState(false)
    const [exchange,setExchange] = useState('');
    const [selectedModalCountry,setSelectedModalCountry] = useState('')
    const [loading,setLoading] = useState(false)
    const [formLoading,setFormLoading] = useState(false)
    const [indiceName,setIndiceName] = useState('')
    const [indicesList,setIndicesList] = useState([])
    const [selectedCountry,setSelectedCountry] = useState('us')
    const [tableData,setTableData] = useState([])
    const countryData = [{id:"us",value:"us",title:"United States"},{id:"oz",value:"oz",title:"Australia"},{id:"ca",value:"ca",title:"Canada"}]
    const [editUpdateId,setEditUpdateId] = useState(null)
    const [isSelected,setIsSelected] = useState(false)
    const [deleteIndiceRow,setDeleteIndiceRow] = useState(null)
    const [deleteModal,setDeleteModal] = useState(false)
    const [deleteLoading,setDeleteLoading] = useState(false)
    


    useEffect(()=>{
      getAllIndices()
    },[])

    useEffect(()=>{
        if(indiceName){
            getIndicesList()
        }
    },[indiceName])

  const openIndicesModal = ()=>{
    setIndiceModal(true)
  }

  const getAllIndices = async(country="us")=>{
    setLoading(true)
    const res = await jobs.getIndice(country)
    if(res?.data?.payload){
      setTableData(res?.data?.payload)
    }else{
      setTableData([])
    }
    setLoading(false)
  }

  const getIndicesList = async()=>{
    const checkDollarSymbol = indiceName[0] == "$" ? indiceName : '$'+indiceName
    const payload = {
      symbol:checkDollarSymbol,
      exchange:exchange
    }
    const res = await symbol.getSymbolData(payload)
    if(res?.length > 0){
      const flattenedArray = res.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
      setIndicesList(flattenedArray)
    }else{
      setIndicesList([])
    }
  }

  const handleClose = ()=>{
    console.log("close")
    setIndiceModal(false)
    setIndiceName('')
    setSelectedModalCountry('')
    setIndiceCustomName('')
    setIndicesList([])
    setEditUpdateId(null)
  }

  const handleSave = async(indiceData)=>{
    if(indiceData?.symbol && indiceCustomName && selectedModalCountry){
    setFormLoading(true)
    const payload = {
      symbol:indiceData?.symbol,
      country:selectedModalCountry,
      desc:indiceCustomName
    }
    const res = await jobs.addIndice(payload)
    if(res?.data?.success){
      toast.success(res?.data?.message)
      getAllIndices(selectedCountry)
    }else{
      toast.error(res?.data?.message)
    }
    handleClose()
    setFormLoading(false)
  }else{
    toast.error("All fields are required")
  }

  }

  const handleCountryChange = (val)=>{
    setSelectedCountry(val)
    getAllIndices(val)
  }

  const handleDelete = (item)=>{
    setDeleteIndiceRow(item)
    setDeleteModal(true)
  
  }

  const handleEdit = (item)=>{
    setSelectedModalCountry(item?.country)
    setIndiceCustomName(item?.desc)
    setIndiceName(item?.symbol)
    setEditUpdateId(item?._id)
    setIndiceModal(true)
  }

  const handleUpdate = async()=>{
    if(indiceName && indiceCustomName && selectedModalCountry){
      if(isSelected){
        setFormLoading(true)
      const payload = {
        symbol:indiceName,
        country:selectedModalCountry,
        desc:indiceCustomName,
        id:editUpdateId
      }
      const res = await jobs.updateIndice(payload)
      if(res?.data?.success){
        toast.success(res?.data?.message)
        getAllIndices(selectedCountry)
      }else{
        toast.error(res?.data?.message)
      }
      handleClose()
      setFormLoading(false)
    }else{
      toast.error("Select valid index from dropdown list")
    }
    }else{
      toast.error("All fields are required")
    }
  }

  const handleIndiceDelete = async()=>{
    setDeleteLoading(true)
    const res = await jobs.deleteIndice(deleteIndiceRow?._id)  
    if(res?.data?.success){
      toast.success(res?.data?.message)
      getAllIndices(selectedCountry)
      onDeleteModalClose()
    }else{
      toast.error(res?.data?.message)
    }
    setDeleteLoading(false)
  }

  const onDeleteModalClose = ()=>{
    setDeleteIndiceRow(null)
    setDeleteModal(false)
    setDeleteLoading(false)
  }

  return (
    <div className="flex w-full h-full  overflow-hidden px-8">
        {indiceModal &&
            <BaseModal>
                <AddIndicesModal loading={formLoading} setIsSelected={setIsSelected} isEdit={editUpdateId} setSelectedCountry={setSelectedModalCountry} indiceCustomName={indiceCustomName} selectedCountry={selectedModalCountry} setIndiceCustomName={setIndiceCustomName} setIndiceName={setIndiceName} setExchange={setExchange} setIndicesList={setIndicesList} indicesList={indicesList} indiceLoading={indiceLoading} handleUpdate={handleUpdate} handleSave={handleSave} handleClose={handleClose} indice={indiceName}/>
            </BaseModal>
        }

        {deleteModal &&
        <BaseModal>
          <DeleteIndice handleClose={onDeleteModalClose} loading={deleteLoading} handleDelete={handleIndiceDelete} />
        </BaseModal>
        
        }

        <div className="w-full h-full bg-white text-black text-left font-semibold text-28">
          <div className="w-full flex items-center justify-between">
          <PageTitle title="Indices Configuration" />
          <LogoutBtn />
          </div>
       
          
        <div className='bg-white space-y-4 px-2 py-4'>
            <div className='w-full flex justify-between'>
            <div className="flex space-x-4">
              {countryData?.map((item,i)=>(
                <div key={i} className='flex items-center'>
                <input
                  type="checkbox"
                  value={item?.value}
                  checked={selectedCountry === item?.value}
                  onChange={() => handleCountryChange(item?.value)}
                  className="form-checkbox cursor-pointer h-4 w-4"
                  id={item?.id}
                />
                <label htmlFor={item?.id} className="ml-2 cursor-pointer text-16 text-primaryColor">{item?.title}</label>
              </div>
              ))}
   
  </div>
                <div>
                  <ButtonComp text={"Add Indices"} BtnClass={`h-10 w-[8vw]`} TextClass='text-16 tracking-wider' onClick={openIndicesModal}/>
                </div>
            </div>
            <div>
                <IndicesTable data={tableData} header={header} loading={loading} onEdit={handleEdit} onDelete = {handleDelete} />
            </div>
        </div>
          
        </div>
    </div>
  )
}

export default AddIndices